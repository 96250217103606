import React, { useContext, useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import {
  FileOpenOutlined,
  FirstPage,
  FmdGoodTwoTone,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  LocationOn,
  MoreVertRounded,
  SearchOffOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Box } from "@mui/system";
import AuthContext from "../../context/AuthContext";

import MapIcon from "../../assets/img/company/location_icon.png";
import CouponIcon from "../../assets/img/company/coupons_icon.png";
import ModalContext from "../../context/ModalContext";
import DrawerContext from "../../context/DrawerContext";
import DescriptionIcon from "@mui/icons-material/Description";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { set } from "firebase/database";
import CustomButton from "../../common/CustomButton";
import TextInput from '../../common/TextInput'

export const generateTempLogo = (name) => {
  let initials = "";
  
  if (!name || name.trim().toUpperCase() === "N/A") {
    initials= "N/A";
  } else {
    initials=name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join("");
  }
  return (
    <div
      style={{
        height: "32px",
        width: "32px",
        borderRadius: "100%",
        backgroundColor: "#576574",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontSize: "12px",
        fontWeight: "bold",
      }}
    >
      {initials}
    </div>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

export default function ERServicesPage() {

  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [loading, setLoading] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // Ensure it's declared here
  const [filterValue, setFilterValue] = useState("");
  const [displayedData, setDisplayedData] = useState(companiesData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedER, setSelectedER] = useState(null);
  const [anchorERAdd, setAnchorERAdd] = useState(null);  
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const [open3, setOpen3] = useState(false);
  const [comapniesNameData, setCompaniesNameData] = useState([]);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorElFilter);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companiesData.length) : 0;

  useEffect(() => {
    setLoading(true);
    getAllERServices();
    getAllCompanies();
  }, []);

  useEffect(() => {
    const filteredData = companiesData.filter((data) =>
      (data.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        (data.company && data.company.title && data.company.title.toLowerCase().includes(searchInput.toLowerCase()))) &&
      data.emergencyService.toLowerCase().includes(filterValue.toLowerCase())
    );
    setDisplayedData(filteredData);
  }, [companiesData, searchInput, filterValue]);

  useEffect(() => {
    if (drawerCtx.pageUpdate?.page === "ERServices") {
      console.log("\n\nPage update requested. Refetching all ER Services...");
      drawerCtx.setPageUpdate({ page: "" });
      getAllERServices();
    } else {
      console.log("No page update requests detected...");
    }
  }, [drawerCtx.pageUpdate]);

  useEffect(() => {
    const searchedData = companiesData.filter((data) =>
      (data.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        (data.company && data.company.title && data.company.title.toLowerCase().includes(searchInput.toLowerCase()))) &&
      data.emergencyService.toLowerCase().includes(filterValue.toLowerCase())
    );
    const filteredData = searchedData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    setDisplayedData(filteredData);

  }, [filterValue, searchInput, loading, companiesData])

  const getAllERServices = () => {
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.status == "success") {
        console.log(data?.data, "this is the data");
        setCompaniesData(data?.data);
        setLoading(false);
      }
    })
    .catch((err) => {
      console.error(err);
    });
  };

  const getAllCompanies = () => {    
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token },
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status == 'success') {
        setCompaniesNameData(data.data);
      } else {
        console.error("Unexpected API response:", data);
      }
    }).catch(err => {
      console.error(err)
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = (status) => {
    if (status == "pending") {
      return <div class="status-pending">Pending</div>;
    } else if (status == "invite_sent") {
      return <div className="status-invite">Invite Sent</div>;
    } else if (status == "active") {
      return <div className="status-active">Active</div>;
    } else if (status == "suspended") {
      return <div class="status-suspended">Suspended</div>;
    } else if (status == "draft") {
      return <div class="status-draft">Draft</div>;
    }  else {
      return <div className="status-unknown">Unknown</div>;
    }
  };

  const handleModal = (modal, serviceId) => {
    handleClose();
    modalCtx.openModal();
    modalCtx.setDetails(modal, serviceId);
  };

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }
  
  const handleClick = (event, data) => {
    setAnchorEl(event.target);
    setSelectedER(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAddBtn = (event) => {
    setAnchorERAdd(event.currentTarget);
    setOpen3(true);
  };

  const handleCloseAddBtn = (type) => {
    setAnchorERAdd(null);
    setOpen3(false); 
    if (type) {
      // const combinedData = [comapniesNameData, type];
      console.log({ comapniesNameData, type });
      toggleDrawerHandler("create-erservices",  { comapniesNameData, type }); 
    }
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  }
  
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };


  const handleCloseFilter = (value) => {
    setAnchorElFilter(null);
    setFilterValue(value);
  };

  const handleStatusClick = (service) => {
    setSelectedER(service);
    if (service.status === "draft") {
      setModalMessage("Do you want to make this a live listing?");
    } else if (service.status === "active") {
      setModalMessage("Would you like to deactivate this listing?");
    }
    setStatusModalOpen(true);
  };

  const handleStatusChange = () => {
    console.log(`Updating status for service ID: ${selectedER?.id}`);
    setStatusModalOpen(false);
    // API call to update status can be implemented here.
  };

  const openDrawer = (type) => {
    if (type) {
      drawerCtx.openDrawer();
      drawerCtx.setDetails("create-erservices", { comapniesNameData });
    }
  };

  const showErType = (erType) => {
    if(erType == "regional") {
      return "er-type-value regional";
    }
    return "er-type-value local";
  }

  return (
    <>
      <div className="erservices-container">
        <div className="header-section">
          <span className="title">EMERGENCY SERVICES</span>
          <div className="header-items-div">
          <div className="text-input"><SearchOutlined /><input type="text" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Search an emergency service" /></div>
          <div>
            <CustomButton className={'deliva-btn-bordered'} onClick={handleClickFilter} >Filter By :- {filterValue !== '' ? filterValue : 'All'}</CustomButton>
            <Menu id="basic-menu" anchorEl={anchorElFilter} open={open2} onClose={() => handleCloseFilter('')} MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
              <MenuItem onClick={() => handleCloseFilter('medical')}>Medical</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('rescue')}>Rescue</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('police')}>Police</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('shelters')}>Shelters</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('fire')}>Fire</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('pests')}>Pests</MenuItem>
              <MenuItem onClick={() => handleCloseFilter('')}>All</MenuItem>
            </Menu>
          </div>
          <div>
            <CustomButton className="add-service-button" onClick={openDrawer} disabled={ loading || comapniesNameData.length === 0}>+ Add Services</CustomButton>
          </div>
          </div>
        </div>
        <TableContainer component={Paper} className="erservices-table">
          <Table sx={{ minWidth: 500 }} stickyHeader>
            <TableHead>
              <TableRow className="erservices-table-head">
                <TableCell className="erservices-head-cell" align="left">
                  &nbsp;
                </TableCell>
                <TableCell align="left" className="erservices-head-cell">
                  Title
                </TableCell>
                <TableCell align="left" className="erservices-head-cell">
                  Company
                </TableCell>
                <TableCell align="left" className="erservices-head-cell">
                  ER Type
                </TableCell>
                <TableCell align="left" className="erservices-head-cell">
                  ER Service
                </TableCell>
                <TableCell align="left" className="erservices-head-cell">
                  Locations
                </TableCell>
                <TableCell align="left" className="erservices-head-cell">
                  Status
                </TableCell>
                <TableCell
                  align="right"
                  className="erservices-head-cell"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="erservices-table-body">
              {loading ? (
                <div className="loader-container">
                  <CircularProgress className="loader"></CircularProgress>
                </div>
              ) : (
                (rowsPerPage > 0
                  ? displayedData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  : displayedData
                ).map((data, index) => (
                  <TableRow key={data.id} className="erservices-table-row" style={{ backgroundColor: index % 2 != 0 ? "#fafafc" : "white" }} >
                    <TableCell className="erservices-table-cell" style={{ width: "50px", padding: 0, paddingLeft: "10px" }} align="left" >
                      {data.company && data.company.logoLg ? (
                        <img src={data.company.logoLg} alt="Company Logo" />
                      ) : (
                        generateTempLogo(data.company?.title || "N/A")
                      )}
                    </TableCell>
                    <TableCell className="erservices-table-cell er-title" align="left">{data.title}</TableCell>
                    <TableCell className="erservices-table-cell er-company" align="left">{data.company?.title}</TableCell>
                    <TableCell className="erservices-table-cell" align="left">
                      <div className={showErType(data.erType)}>{data.erType}</div>
                    </TableCell>
                    <TableCell className="erservices-table-cell er-service-name"
                      style={{ width: "120px" }}
                      align="left"
                    >
                      {data.emergencyService}
                    </TableCell>
                    <TableCell className="erservices-table-cell er-service-name" align="left">
                      <div className="couponDiv">
                        <img className="mapIcon" src={MapIcon}></img>
                        {/* <LocationOn/> */}
                        {data.locations ? (
                          <span>{data.locations.length}</span>
                        ) : null }
                      </div>
                    </TableCell>
                    <TableCell className="erservices-table-cell" align="center" onClick={() => handleStatusClick(data)} style={{ cursor: "pointer", color: data.status === "draft" ? "gray" : "inherit" }}>
                      <div className={`status-${data.status}`}>
                        {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
                      </div>
                    </TableCell>
                    <TableCell className="erservices-table-cell"
                      style={{ width: "80px" }}
                      align="left"
                    >
                      <div className="icons-container">
                        {/* <DescriptionIcon/> */}
                        <MoreVertRounded
                          aria-controls={open ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => handleClick(e, data)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} className="erservices-table-pagination">
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={displayedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Menu id={`fade-menu$`} MenuListProps={{ "aria-labelledby": `fade-button`, }} anchorEl={anchorEl} open={open} onClose={handleClose} TransitionComponent={Fade}>
        <MenuItem onClick={() => { setAnchorEl(null); toggleDrawerHandler("edit-erservices", { selectedER, comapniesNameData }); }}>Manage ER Service</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); toggleDrawerHandler("manage-erlocations", { selectedER, comapniesNameData }); }}>Manage Locations</MenuItem>
        <MenuItem onClick={() => handleModal("delete-service", selectedER?.id)}>Delete Service</MenuItem>
      </Menu>
      {/* Status Change Modal */}
      <Dialog open={statusModalOpen} onClose={() => setStatusModalOpen(false)} className="custom-modal">
        <DialogTitle className="modal-header">Status Change</DialogTitle>
        <DialogContent className="modal-content">{modalMessage}</DialogContent>
        <DialogActions className="modal-actions">
          <Button onClick={() => setStatusModalOpen(false)} className="modal-button no">No</Button>
          <Button onClick={handleStatusChange} className="modal-button yes">Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
