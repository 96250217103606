
import React, { useState, useContext, useEffect, Component } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@mui/material'

import CreateNetworkDrawer from '../utils/Drawers/CreateNetworkDrawer';

import CreateBlogDrawer from '../utils/Drawers/Blogs/CreateBlogDrawer';
import EditBlogDrawer from '../utils/Drawers/Blogs/EditBlogDrawer';

import DrawerContext from '../context/DrawerContext.js';
// import CreateCompanyDrawer from '../utils/Drawers/Companies/AddCompanyDrawer';
import AddCompanyDrawer from '../utils/Drawers/Companies/AddCompanyDrawer';
import ManageLocationDrawer from '../utils/Drawers/Companies/ManageLocationDrawer.jsx';
import ManageCompanyDrawer from '../utils/Drawers/Companies/ManageCompanyDrawer.jsx';
import CreateEmergencyServiceDrawer from '../utils/Drawers/ERServices/CreateEmergencyServicesDrawer.jsx';
import EditEmergencyServiceDrawer from '../utils/Drawers/ERServices/EditEmergencyServicesDrawer.jsx';
import ManageERLocationsDrawer from '../utils/Drawers/ERServices/ManageERLocationsDrawer.jsx';
import AddCategory from '../utils/Drawers/Categories/AddCategoryDrawer.jsx';
import EditCategory from '../utils/Drawers/Categories/EditCategoryDrawer.jsx';
import AddAgent from '../utils/Drawers/Agents/AddAgent.jsx';
const noMenuRoutes = ["/login", "/register"];

function DetailsDrawer(props) {

  const drawerCtx = useContext(DrawerContext);
  const { pathname } = useLocation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function closeDrawer() {
    drawerCtx.closeDrawer();
  }

  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  const currentDrawer = () => {
    switch (drawerCtx.drawer) {

      // Users
      case "create-user": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>Create New User</h1></Drawer>;
      case "create-network": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateNetworkDrawer /></Drawer>;

      // Blogs
      case "create-blog": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateBlogDrawer /></Drawer>;
      case "edit-blog": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditBlogDrawer drawerDetails={drawerCtx.details} /></Drawer>;

       // Company
       case "add-company": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><AddCompanyDrawer /></Drawer>;
       case "edit-company": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditBlogDrawer drawerDetails={drawerCtx.details} /></Drawer>;
       case "manage-locations": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><ManageLocationDrawer drawerDetails={drawerCtx.details} /></Drawer>;
       case "manage-company": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><ManageCompanyDrawer drawerDetails={drawerCtx.details} /></Drawer>;
 
      // ER Services
      case "create-erservices": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateEmergencyServiceDrawer drawerDetails={drawerCtx.details} /></Drawer>;
      case "edit-erservices": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditEmergencyServiceDrawer drawerDetails={drawerCtx.details} /></Drawer>;
      case "manage-erlocations": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><ManageERLocationsDrawer drawerDetails={drawerCtx.details} /></Drawer>;
      case "add-agent": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><AddAgent drawerDetails={drawerCtx.details} /></Drawer>;
      case "edit-agent": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><AddAgent drawerDetails={drawerCtx.details} /></Drawer>;
      
      // Categories Page
      case "add-category" : return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><AddCategory drawerDetails={drawerCtx.details}/> </Drawer>;
      case "edit-category" : return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditCategory drawerDetails={drawerCtx.details}/> </Drawer>;
      
      default: return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>;
    }
  }

  // if(drawer) {
  return (
    <div>{currentDrawer()}</div>
  );
  // } else {
  // 	return null;
  // }

  // return (
  // 	<>
  // 	<Drawer 
  // 	anchor='right'
  // 	classes={{ paper: classes.drawerContainer}}
  // 	onClose={() => setOpenDrawer(false)} 
  // 	open={openDrawer}
  // 	onOpen={() => setOpenDrawer(true)}>

  // 			<List>
  // 			{/* <h1>{this.props.name}</h1> */}
  // 				<Tabs value={selectedTab} onChange={handleChange}>

  // 					<Tab label="Details" />
  // 					<Tab label="Translation" />
  // 					<Tab label="Statistics" />

  // 				</Tabs>
  // 			</List>
  // 	</Drawer>
  // 		{/* <Button
  // 		className={classes.MoreVertIcon}
  // 		onClick={() => setOpenDrawer(!openDrawer)}
  // 		disableRipple>
  // 		{/* <MenuIcon className={classes.MoreVertIcon} />
  // 	</Button> */}
  // 	</>

  // );

}
export default DetailsDrawer;
