//react
import React, { useContext, useEffect, useState } from "react";

//mui
import { CheckCircleOutline } from '@mui/icons-material';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, InputAdornment, Snackbar, SnackbarContent, Switch, FormGroup, FormControlLabel, CircularProgress, Tabs, Tab, RadioGroup, FormLabel, Radio, Checkbox, Autocomplete, createFilterOptions, Chip } from "@mui/material";


//context
import AuthContext from "../../../context/AuthContext";
import DrawerContext from "../../../context/DrawerContext";
import { generateTempLogo } from '../../../Pages/ERServices/ERServices.jsx';
import "./EditEmergencyServiceDrawer.scss";

//componen
const filter = createFilterOptions();

const EditEmergencyServiceDrawer = ({drawerDetails}) => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const { serviceData, comapniesNameData } = drawerDetails; 

  const [companyValue, setCompanyValue] = useState(null);
  const [erServiceTitleValue, setERServiceTitleValue] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarContentText, setSnackbarContentText] = useState("");
  const [isDetailsValid, setIsDetailsValid] = useState(false);
  const [originalCompaniesList, setOriginalCompaniesList] = useState([]);
  const [createdServiceId, setCreatedServiceId] = useState(null);


  const [editEmergencyServiceObj, setEditEmergencyServiceObj] = useState({
    title: "",
    company: "",
    priority: "",
    phone: "",
    description: "",
    keywords: [],
  });

  const Details = comapniesNameData || [];

  const [serviceType] = useState("local");
  const [setCountries] = useState([]);
  const [countryValue, setCountryValue] = useState(null);
  const [erType, setERType] = useState("Local");
  const [subRegionalType, setSubRegionalType] = useState("");
  
  
    const [categoryValue, setCategoryValue] = useState("");

  const [checkboxState, setCheckBoxState] = React.useState({
    phoneCheckbox: true,
    navigateCheckbox: false,
  });
  const {  phoneCheckbox, navigateCheckbox } = checkboxState;

  const [isCreating, setIsCreating] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [state, setState] = useState("");

  const emergencyContacts = [
    { Category: 'National Emergency Number', title: 'NATIONAL EMERGENCY HOTLINE', Priority: 1, type: 'police' },
    { Category: 'Ambulance Service (Public)', title: 'PUBLIC AMBULANCE', Priority: 5, type: 'medical' },
    { Category: 'Ambulance Service (Private)', title: 'PRIVATE AMBULANCE (PAID)', Priority: 6, type: 'medical' },
    { Category: 'Animal Hospital (or Veterinarian)', title: 'VETERINARIAN', Priority: 19, type: 'pests' },
    { Category: 'Air Ambulance', title: 'AIR AMBULANCE', Priority: 7, type: 'medical' },
    { Category: 'Disaster Management', title: 'DISASTER MANAGEMENT', Priority: 15, type: 'fire' },
    { Category: 'Fire Brigade', title: 'FIRE FIGHTERS', Priority: 4, type: 'fire' },
    { Category: 'Helpline (Childline)', title: 'CHILDREN’S HELPLINE', Priority: 20, type: 'police' },
    { Category: 'Helpline (Rapeline)', title: 'RAPE ASSISTANCE HELPLINE', Priority: 21, type: 'police' },
    { Category: 'Helpline (Depression & Anxiety)', title: 'DEPRESSION & ANXIETY HELPLINE', Priority: 22, type: 'medical' },
    { Category: 'Helpline (Suicide Prevention)', title: 'SUICIDE PREVENTION HELPLINE', Priority: 23, type: 'medical' },
    { Category: 'Helpline (Poison)', title: 'POISON HELPLINE', Priority: 24, type: 'medical' },
    { Category: 'Hospital (Public)', title: 'PUBLIC HOSPITAL', Priority: 8, type: 'medical' },
    { Category: 'Hospital (Private)', title: 'PRIVATE HOSPITAL (PAID)', Priority: 9, type: 'medical' },
    { Category: 'Hospital (Military)', title: 'MILITARY HOSPITAL (PAID)', Priority: 10, type: 'medical' },
    { Category: 'Humanitarian Organization', title: 'HUMANITARIAN RELIEF', Priority: 33, type: 'shelters' },
    { Category: 'Municipal Emergency Number', title: 'MUNICIPAL EMERGENCIES', Priority: 34, type: 'fire' },
    { Category: 'Neighborhood Watch', title: 'NEIGHBORHOOD WATCH', Priority: 28, type: 'police' },
    { Category: 'Pest Control (Baboon Control)', title: 'BABOON CONTROL', Priority: 25, type: 'pests' },
    { Category: 'Pest Control (Beekeeper)', title: 'BEEKEEPER', Priority: 26, type: 'pests' },
    { Category: 'Pest Control (Snake Handler)', title: 'SNAKE HANDLER', Priority: 27, type: 'pests' },
    { Category: 'Police Service', title: 'POLICE', Priority: 2, type: 'police' },
    { Category: 'Rescue (Mountain)', title: 'MOUNTAIN RESCUE', Priority: 12, type: 'rescue' },
    { Category: 'Rescue (Wilderness / Offroad)', title: 'OFFROAD & WILDERNESS RESCUE', Priority: 13, type: 'rescue' },
    { Category: 'Rescue (Sea)', title: 'SEA RESCUE', Priority: 14, type: 'rescue' },
    { Category: 'Rescue (Lifesavers)', title: 'LIFESAVERS', Priority: 15, type: 'rescue' },
    { Category: 'Shelter (Safehouse)', title: 'SAFEHOUSE', Priority: 17, type: 'shelters' },
    { Category: 'Shelter (Night Shelter)', title: 'NIGHT SHELTER', Priority: 18, type: 'shelters' },
    { Category: 'Tipline (Crime)', title: 'REPORT CRIME', Priority: 29, type: 'police' },
    { Category: 'Tipline (Fraud)', title: 'REPORT FRAUD', Priority: 30, type: 'police' },
    { Category: 'Tipline (Human Trafficking)', title: 'REPORT HUMAN TRAFFICKING', Priority: 31, type: 'police' },
    { Category: 'Tipline (Shark Sightings)', title: 'REPORT SHARK SIGHTINGS', Priority: 32, type: 'pests' },
    { Category: 'Traffic Department', title: 'TRAFFIC DEPARTMENT', Priority: 3, type: 'police' },
    { Category: 'Veterinarian (or Animal Hospital)', title: 'VETERINARIAN', Priority: 19, type: 'pests' },
    { Category: 'Volunteer Firefighters', title: 'VOLUNTEER FIRE FIGHTERS', Priority: 11, type: 'fire' },
  ];

  useEffect(() => {
    if (drawerDetails?.selectedER) {
      const serviceData = drawerDetails.selectedER;
      setEditEmergencyServiceObj({
        title: serviceData.title || "",
        company: serviceData.companyId || "",
        priority: serviceData.priority || "",
        phone: serviceData.phone || "",
        description: serviceData.description || "",
        keywords: serviceData.keywords ? serviceData.keywords.split("|") : [],
      });
  
      setERServiceTitleValue(serviceData.title || "");
      setKeywords(serviceData.keywords ? serviceData.keywords.split("|") : []);
    }
  }, [drawerDetails]); 

  useEffect(() => {
    if (drawerDetails?.selectedER) {
      setCompanyValue(drawerDetails.selectedER.company);
    }
  }, [comapniesNameData, drawerDetails]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    getAllCompanies();
  },[])

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (!checked && phoneCheckbox && navigateCheckbox) {
      setCheckBoxState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (checked) {
      setCheckBoxState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (!checked && !phoneCheckbox && !navigateCheckbox) {
      return;
    }
  };

  const getAllCompanies = () => {
    console.log(Details, "this is company the data drawer details");
    const companiesList = Details.map(company => ({
      id: company.id,
      title: company.companyDetails.company,
      image: company.logo
    }));

    setCompaniesData(companiesList);
    setOriginalCompaniesList(Details);
  }

  const editEmergencyService = () => {
    
    setIsCreating(true);
    const emergencyService = emergencyContacts.find((contact)=> contact.title === erServiceTitleValue);
    if (serviceType === "regional") {
      payload.state = state;
    }

    if (!emergencyService) {
      console.error("Emergency Service not found!");
      setIsCreating(false);
      return;
    }
    
    const payload = {
      erType: erType || "local",
      priority: emergencyService.Priority,
      companyId: companyValue?.id,
      emergencyService: emergencyService.type,
      title: emergencyService.title,
      description: editEmergencyServiceObj.description,
      keywords: keywords ? keywords.join("|") : "",
      phone: editEmergencyServiceObj.phone,
      phoneBtn: phoneCheckbox ? "yes" : "no",
      navigateBtn: navigateCheckbox ? "yes" : "no",
      regionalType: categoryValue
    }
    
    console.log(payload);
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify(payload)
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status == 'success') {
        console.log("Edited Emergency Service:", data);
        setCreatedServiceId(data?.data?.id);
        drawerCtx.setPageUpdate({page: "ERServices"});
        drawerCtx.closeDrawer();
        setIsCreating(false);
      }
    }).catch(err => {
      console.error(err)
      setIsCreating(false);
    });
  }; 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditEmergencyServiceObj((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));    
  };
  
  const isSaveDisabled = () => {
    const isCommonFieldsValid =
      !!companyValue &&
      !!erServiceTitleValue &&
      !!editEmergencyServiceObj.phone &&
      !!editEmergencyServiceObj.description &&
      Array.isArray(keywords) &&
      keywords.length > 0;
  
    const isRegionalValid = serviceType === "regional" ? !!countryValue && (categoryValue === "national" || !!state) : true;
  
    return !(isCommonFieldsValid && isRegionalValid && !isCreating);
  };   

  return (
    <div className="drawer-container">
      <div className="drawer edit-emergency-services-drawer">
      <header className="drawer-header"><h1 data-aos="fade-right" data-aos-delay="500">Edit Emergency Services</h1></header>
        <section className="drawer-content" style={{ padding: "0rem", display: "flex", flexDirection: "column", gap: "2rem", }}>
          <div style={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "2rem", minHeight:"794px",}}>
            <Autocomplete className="full-width-column" value={companyValue || null}
              onChange={(event, newValue) => {
                console.log(newValue);
                if (newValue) {
                  setCompanyValue(newValue || null);
                } else {
                  setCompanyValue(null);
                }
              }}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                // Suggest the creation of a new value
                return options.filter((option) =>
                  option.title.toLowerCase().includes(inputValue.toLowerCase())
                );
              }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="company-list"
                options={companiesData.filter((company) => company.title)}
                getOptionLabel={(option) => option?.title || "Unknown"}
                renderOption={(props, option) => (
                  <li {...props} key={option.id || option.title} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {option.image ? (
                      <img src={option.image} alt="Company Logo" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                    ) : (
                      generateTempLogo(option.title)
                    )}
                    <span>{option.title}</span>
                  </li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField className="full-width-column" fullWidth {...params} label="Company" />
                )}
                InputProps={{
                  style: {
                    padding: "0",      
                    height: "56px",       
                    display: "flex",
                    alignItems: "center",  
                    boxSizing: "border-box", 
                  },
                }}
                inputProps={{
                  style: {
                    padding: "0 16px",  
                    height: "100%",  
                    lineHeight: "1.5", 
                  },
                }}
                style={{
                  marginBottom: "16px",      
                }}
                InputLabelProps={{
                  style: {
                    transform: "translate(0, 10px) scale(1)", 
                  },
                }}
              />
            <Autocomplete
              className="full-width-column"
              value={erServiceTitleValue}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setERServiceTitleValue({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setERServiceTitleValue({
                    title: newValue.inputValue,
                  });
                } else {
                  setERServiceTitleValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="emergency-service-list"
              options={emergencyContacts}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option ) => (
                <li
                  {...props}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                    alignItems: 'center',
                    borderBottom: '1px solid #eee',
                    backgroundColor: props['aria-selected'] ? '#f0f0f0' : 'white',
                    cursor: 'pointer',
                  }}
                >
                  <span style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}>{option.title}</span>
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: '400',
                      color: '#666',
                      backgroundColor: '#e0e0e0',
                      padding: '2px 8px',
                      borderRadius: '12px',
                    }}
                  >
                    Priority: {option.Priority}
                  </span>
                </li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField className="full-width-column" fullWidth {...params} label="Emergency Service" />
              )}
              InputProps={{
                style: {
                  padding: "0",             
                  height: "56px",          
                  display: "flex",
                  alignItems: "center",      
                  boxSizing: "border-box",   
                },
              }}
              inputProps={{
                style: {
                  padding: "0 16px",    
                  height: "100%",          
                  lineHeight: "1.5",    
                },
              }}
              style={{
                marginBottom: "16px",    
              }}
              InputLabelProps={{
                style: {
                  transform: "translate(0, 10px) scale(1)",
                },
              }}
            />
            <div className="" style={{ width: "100%", display: "flex" }}>
              <TextField
                label="Phone Number"
                name="phone"
                value={editEmergencyServiceObj.phone}
                onChange={handleInputChange}
                fullWidth
              />
            </div>
            <TextField
              label="Description"
              name="description"
              value={editEmergencyServiceObj.description}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={3}
            />

            <Autocomplete
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              multiple
              id="keywords-list"
              options={[]}
              freeSolo
              value={keywords || []}
              onChange={(event, newValue) => {
                if (newValue) {
                  setKeywords(Array.isArray(newValue) ? [...newValue] : []);
                } else {
                  setKeywords([]);
                }
              }}
              renderTags={(value, getTagProps) =>
                (keywords || []).map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField className="full-width-column" fullWidth {...params} label="Keywords" />
              )}
            />     
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <FormLabel component="legend">Emergency Service Type</FormLabel>
                <RadioGroup row value={erType} onChange={(e) => setERType(e.target.value)} name="emergency-service-type">
                  <FormControlLabel value="Local" control={<Radio />} label="Local" />
                  <FormControlLabel value="Regional" control={<Radio />} label="Regional" />
                </RadioGroup>
            </div>
            {erType === "Local" && (
              <div className="flex-drawer">
                <FormControlLabel control={ <Checkbox checked={phoneCheckbox} onChange={handleCheckboxChange} name="phoneCheckbox" /> } label="Phone Button Visible" />
                <FormControlLabel control={ <Checkbox checked={navigateCheckbox} onChange={handleCheckboxChange} name="navigateCheckbox" /> } label="Navigate Button Visible" />
              </div>
            )}
            {erType === "Regional" && (
              <div style={{ marginTop: "1rem" }}>
                <FormLabel component="legend">Regional Type</FormLabel>
                <RadioGroup row value={subRegionalType} onChange={(e) => setSubRegionalType(e.target.value)} name="regional-type">
                  <FormControlLabel value="National" control={<Radio />} label="National" />
                  <FormControlLabel value="Regional" control={<Radio />} label="Regional" />
                </RadioGroup>
              </div>
            )}
            <footer className="manage-location-buttons">
              <button variant="contained" className="btn-primary cancel-button" onClick={(e) => drawerCtx.closeDrawer()}>Cancel</button>
              <button variant="contained" disabled={isSaveDisabled()} onClick={(e) => editEmergencyService()} className="btn-primary save-button">
                {isCreating ? <CircularProgress size={24} style={{ color: "#fff" }} /> : ""} Save
              </button>
            </footer>
          </div>
        </section>
      </div>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <SnackbarContent style={{ backgroundColor: 'green', borderRadius: '10px', color: 'white', padding: '12px 20px', display: 'flex', alignItems: 'center', }}
          message={
            <span id="client-snackbar" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutline style={{ marginRight: '8px', fontSize: '20px' }} />
              {snackbarContentText}
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};

export default EditEmergencyServiceDrawer;