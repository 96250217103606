import React, { useEffect, useState, useContext } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from "@mui/material";
import DrawerContext from "../../../context/DrawerContext";
import AuthContext from "../../../context/AuthContext";


const ManageLocationsDrawer = ({ drawerDetails }) => {
  
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);
  const erServiceId = drawerDetails?.selectedER?.id;
  const erServiceLocations = drawerDetails?.selectedER?.locations;
  const companyId = drawerDetails?.selectedER?.companyId || drawerDetails?.selectedER?.company?.id;
  const authToken = authCtx?.token;
  
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    if (!companyId || !authToken) return; 
  
    console.log("Fetching locations for companyId:", companyId);
    setIsLoading(true);
  
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies/${companyId}/locations`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authCtx?.token}`,
      },
    })
    .then((response) => {
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
    .then((data) => {
      console.log("Locations received:", data);
      setLocations(Array.isArray(data.data) ? data.data : []);
      data.data.forEach((loc) => {
        let serviceLocation = erServiceLocations.find((obj) => obj.locationId == loc.id);
        if(serviceLocation) {
          setSelectedLocations(prevSelected => [...prevSelected, loc.id]);
        }
      })
    })
    .catch((error) => console.error("Error fetching locations:", error))
    .finally(() => setIsLoading(false));
  }, [companyId, authToken]);

  const handleCheckboxChange = (locationId) => {
    setSelectedLocations(prevSelected =>
      prevSelected.includes(locationId) ? prevSelected.filter(id => id !== locationId) : [...prevSelected, locationId]
    );
  };

  const isSaveDisabled = () => {
    return selectedLocations.length === 0 || isCreating;
  };

  const editEmergencyServiceLocation = () => {
    if (!selectedLocations || selectedLocations.length === 0) {
      console.error("Error: No locations selected.");
      return;
    }
    setIsCreating(true);
    const payload = { erServiceId: erServiceId, locationIds: selectedLocations };
    console.log("Sending Payload:", JSON.stringify(payload));
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services/${companyId}/locations`, {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${authCtx?.token}` },
      body: JSON.stringify(payload),
    })
    .then((response) => {
      console.log("Response Status:", response.status);
      return response.json();
    })
    .then((data) => {
      console.log("API Response:", data);
      if (data.status !== "success") {
        throw new Error(data.message || "Failed to save locations");
      }
      drawerCtx.setPageUpdate({page: "ERServices"});
      drawerCtx.closeDrawer();
      setIsCreating(false);
    })
    .catch((error) => {
      console.error("Error saving locations:", error);
      setIsCreating(false);
    });
  };

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1>Edit Emergency Service Locations</h1>
        </header>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress size={34} />
          </div>
        ) : (
          <TableContainer className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="erservices-table-cell">Select</TableCell>
                  <TableCell className="erservices-table-cell">Title</TableCell>
                  <TableCell className="erservices-table-cell">Address</TableCell>
                  <TableCell className="erservices-table-cell">Longitude</TableCell>
                  <TableCell className="erservices-table-cell">Latitude</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="erservices-table-body">
                {locations.length ? (
                  locations.map((location) => (
                    <TableRow key={location.id} className="erservices-table-row">
                      <TableCell className="erservices-table-cell">
                        <input
                          type="checkbox"
                          checked={selectedLocations.includes(location.id)}
                          onChange={() => handleCheckboxChange(location.id)}
                        />
                      </TableCell>
                      <TableCell className="erservices-table-cell">{location.title}</TableCell>
                      <TableCell className="erservices-table-cell">{location.address}</TableCell>
                      <TableCell className="erservices-table-cell">{location.longitude}</TableCell>
                      <TableCell className="erservices-table-cell">{location.latitude}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No locations found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <footer className="manage-location-buttons">
          <button className="btn-primary cancel-button" onClick={(e) => drawerCtx.closeDrawer()}>Cancel</button>
          <button disabled={isSaveDisabled()} onClick={(e) => editEmergencyServiceLocation()} className="btn-primary save-button">
            {isCreating ? <CircularProgress size={24} style={{ color: "#fff" }} /> : ""} Save
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ManageLocationsDrawer;